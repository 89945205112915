import React, { useEffect, useRef, useState } from 'react'

import { Bar, Doughnut } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  ArcElement,
  CategoryScale,
  LinearScale,
  BarElement
} from 'chart.js'
import moment from 'moment'
import {
  CheckIcon,
  ChevronDownIcon,
  InfoIcon,
  RefreshIcon,
  SemiCircleIcon
} from '../Icons/Icons'
import Button2 from '../Button/Button2'
import { Fade, Menu } from '@mui/material'
import { getAppReport, getTotalActivity } from '../../store/api'
import { Loader } from '../../components'

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement)

const UserInsight = () => {
  const [selectedDateFilter, setSelectedDateFilter] = useState(30)
  const [anchorEl, setAnchorEl] = useState(null)
  const datesFilter = [7, 14, 30, 60, 90, 180]
  const open = Boolean(anchorEl)
  const [topUsers, setTopUsers] = useState([])
  const [dateRange, setDateRange] = useState(null)
  const [totalFileReport, setTotalFileReport] = useState(null)
  const [weekWiseUserData, setWeekWiseUserData] = useState({})
  const [usersData, setUsersData] = useState([])
  const [datesData, setDatesData] = useState([])
  const [selectedUser, setSelectedUser] = useState(null)
  const [loading, setLoading] = useState(true)
  const [staleData, setStaleData] = useState(false)
  const [staleDateRange, setStaleDateRange] = useState(null)
  useEffect(() => {
    const endDate = new Date()
    const startDate = new Date()
    startDate.setDate(endDate.getDate() - selectedDateFilter)

    const start = startDate.toISOString().split('T')[0]
    const end = endDate.toISOString().split('T')[0]

    console.log({ start_date: start, end_date: end })

    setDateRange({ start_date: start, end_date: end })
  }, [selectedDateFilter])

  const fetchData = async (force = false) => {
    const res = await getAppReport({
      start_date: dateRange?.start_date,
      end_date: dateRange?.end_date,
      hard_refresh: force
    })

    let stale1 = false
    let stale2 = false
    if (res.status === 200) {
      setTotalFileReport(res.data.data)
      stale1 = res?.data?.stale
      if (stale1) {
        setStaleDateRange(res.data?.date_range)
      }
    }

    const res2 = await getTotalActivity({
      start_date: dateRange?.start_date,
      end_date: dateRange?.end_date,
      hard_refresh: force
    })

    if (res2.status === 200) {
      const topUsersList = allUsers(res2.data.data)

      setWeekWiseUserData(res2.data.data)

      setTopUsers(topUsersList)

      stale2 = res2.data.stale

      // if (stale2) {
      //   setStaleDateRange(res2.data.date_range)
      // }
    }

    setStaleData(stale1 || stale2)

    setLoading(false)
  }

  useEffect(() => {
    if (dateRange) {
      setLoading(true)
      fetchData()
    }
  }, [dateRange])

  const timeSavings = [
    {
      name: 'Proposal Analysis',
      time:
        totalFileReport?.total_time_savings?.proposal_analysis_time_savings ||
        0,
      color: '#cdb4db'
    },
    {
      name: 'RFx',
      time: totalFileReport?.total_time_savings?.rfx_time_savings || 0,

      color: '#ffafcc'
    },
    {
      name: 'Compliance',
      color: '#a2d2ff',
      time: totalFileReport?.total_time_savings?.compliance_time_savings || 0
    },
    {
      name: 'Search History',
      color: '#fec89a',
      time:
        totalFileReport?.total_time_savings?.search_history_time_savings || 0
    },
    {
      name: 'Chat Sessions',
      color: '#f4978e',
      time: totalFileReport?.total_time_savings?.chat_sessions_time_savings || 0
    }
  ]

  const chartInstanceRef = useRef(null)

  useEffect(() => {
    if (weekWiseUserData && !loading) {
      const dates = Object.keys(weekWiseUserData)
      const users = [
        ...new Set(dates.flatMap((date) => Object.keys(weekWiseUserData[date])))
      ]

      // sort the users by count

      setDatesData(dates)

      setUsersData(users)

      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()
      }

      return () => {
        if (chartInstanceRef.current) {
          chartInstanceRef.current.destroy()
        }
      }
    }
  }, [weekWiseUserData])

  const textCenter = {
    id: 'textCenter',
    beforeDatasetsDraw(chart, args, pluginOptions) {
      const { ctx, data } = chart
      ctx.save()
      ctx.font = '500 25px sans-serif'
      ctx.fillStyle = 'var(--grey-800)'
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillText(
        moment
          .duration(
            timeSavings.reduce((acc, time) => acc + time.time, 0),
            'minutes'
          )
          .asHours()
          .toFixed(0) < 1
          ? moment
              .duration(
                timeSavings.reduce((acc, time) => acc + time.time, 0),
                'minutes'
              )
              .asHours()
              .toFixed(0) + ' mins'
          : moment
              .duration(
                timeSavings.reduce((acc, time) => acc + time.time, 0),
                'minutes'
              )
              .asHours()
              .toFixed(0) + ' hrs',

        chart.getDatasetMeta(0).data[0].x,
        chart.getDatasetMeta(0).data[0].y
      )
    }
  }

  const topLine = {
    id: 'topLine',
    afterDatasetsDraw(chart, args, plugins) {
      const { ctx, data } = chart

      ctx.save()
      // weekly unique users count in array
      const weeklyUniqueUsers = Object.keys(weekWiseUserData).map((date) => {
        return Object.keys(weekWiseUserData[date]).length
      })

      if (selectedUser) {
        return
      }

      chart.getDatasetMeta(0).data.forEach((datapoint, index) => {
        ctx.beginPath()
        // ctx.strokeStyle = data.datasets[0].borderColor[index]
        // ctx.lineWidth = 3

        // ctx.lineTo(datapoint.x + halfWidth, datapoint.y - 6)
        // ctx.stroke()
        // text
        ctx.font = 'bold 12px sans-serif'
        ctx.fillStyle = 'var(--grey-600)'
        ctx.textAlign = 'center'
        ctx.fillText(weeklyUniqueUsers[index], datapoint.x, 35)
      })
    }
  }

  return (
    <div>
      <div className="p-2 px-4 flex justify-between items-center">
        <div className="font-medium text-base ">Account Insights</div>

        <div>
          {loading ? (
            <Button2
              secondary
              noOutline
              style={{
                marginLeft: 'auto',
                background: 'var(--orange-100)',
                color: 'var(--orange-600)',
                fontFamily: 'PoppinsMedium'
              }}
            >
              <SemiCircleIcon className="size-3 animate-spin" /> loading
            </Button2>
          ) : (
            <Button2
              secondary
              style={{
                marginLeft: 'auto'
              }}
              onClick={(event) => {
                setAnchorEl(event.currentTarget)
              }}
            >
              Last {selectedDateFilter} days{' '}
              <ChevronDownIcon className="size-3" />
            </Button2>
          )}

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            MenuListProps={{
              'aria-labelledby': 'basic-button'
            }}
            TransitionComponent={Fade}
            className="p-2"
          >
            {datesFilter.map((days) => {
              return (
                <Button2
                  secondary
                  noOutline
                  style={{
                    // padding: '4px 16px',
                    margin: '4px'
                    // width: '30px'
                  }}
                  key={days}
                  onClick={() => {
                    setSelectedDateFilter(days)
                    setAnchorEl(null)
                  }}
                >
                  Last {days} days{' '}
                  <CheckIcon
                    className={`size-3 ml-1
                      ${selectedDateFilter === days ? 'visible' : 'invisible'}
                    `}
                  />
                </Button2>
              )
            })}
          </Menu>
        </div>
      </div>
      {loading ? (
        <Loader loading={loading} flex caption={'fetching your insights'} />
      ) : (
        <div>
          <div
            className="px-1 bg-grey-50"
            style={{
              height: 'calc(100% - 41px)',
              overflowY: 'auto'
            }}
          >
            <div className="gap-3 p-4">
              {staleData && (
                <div className="bg-grey-800 p-2 flex rounded-lg text-grey-100 items-center gap-2 justify-center mb-3 ">
                  {/* <InfoIcon className="size-3 text-white" /> */}
                  <p className="m-0 text-xs">
                    Displaying data from{' '}
                    {moment(staleDateRange?.start_date).format('Do MMM, YYYY')}{' '}
                    to {moment(staleDateRange?.end_date).format('Do MMM, YYYY')}{' '}
                    {staleDateRange?.updated_at}
                    {/* {staleDateRange?.end_date.split(' ')[1]} */}
                  </p>

                  <Button2
                    secondary
                    noOutline
                    style={{
                      color: 'var(--grey-100)',
                      border: 'none',
                      background: 'var(--grey-700)'
                    }}
                    onClick={() => {
                      setLoading(true)
                      fetchData(true)
                    }}
                  >
                    <RefreshIcon className="size-3" />
                    Refresh Data
                  </Button2>
                </div>
              )}
              <div>
                <div className="grid grid-col-4 gap-2">
                  <div
                    className="rounded-xl p-3 bg-white"
                    style={{
                      border: '1px solid var(--grey-200)',
                      padding: '14px'
                    }}
                  >
                    <p className="m-0 text-xs font-medium text-grey-500">
                      Proposals Uploaded
                    </p>
                    <p className="text-3xl m-0 font-medium">
                      {totalFileReport?.total_proposals}
                    </p>
                  </div>

                  <div
                    className="rounded-xl p-3 bg-white"
                    style={{
                      border: '1px solid var(--grey-200)',
                      padding: '14px'
                    }}
                  >
                    <p className="m-0 text-xs font-medium text-grey-500">
                      Analytics Uploaded
                    </p>
                    <p className="text-3xl m-0">
                      {totalFileReport?.total_analytic_files}
                    </p>
                  </div>
                  <div
                    className="rounded-xl p-3 bg-white"
                    style={{
                      border: '1px solid var(--grey-200)',
                      padding: '14px'
                    }}
                  >
                    <p className="m-0 text-xs font-medium text-grey-500">
                      RFx Uploaded
                    </p>
                    <p className="text-3xl m-0">
                      {totalFileReport?.total_rfx_files}
                    </p>
                  </div>

                  <div
                    className="rounded-xl p-3 bg-white"
                    style={{
                      border: '1px solid var(--grey-200)',
                      padding: '14px'
                    }}
                  >
                    <p className="m-0 text-xs font-medium text-grey-500">
                      Compliance Checks
                    </p>
                    <p className="text-3xl m-0 font-medium">
                      {totalFileReport?.total_analytics_compliance}
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="grid grid-col-2 mt-3 gap-3 bg-white p-3 rounded-xl"
                style={{
                  border: '1px solid var(--grey-200)'
                  // height: '300px'
                }}
              >
                <div className="">
                  <p className="m-0 px-3 mt-3 text-xs text-grey-500">
                    Total user activity (events)
                  </p>

                  <div
                    className="grid gap-2 grid-col-2 px-3 p-2 mt-4"
                    style={{
                      maxHeight: '300px',
                      overflowY: 'auto'
                    }}
                  >
                    {topUsers.length === 0 ? (
                      <div className="m-0 mt-3 text-xs text-grey-500">
                        No user activity found
                      </div>
                    ) : (
                      <>
                        {topUsers.map((user, i) => {
                          return (
                            <div
                              key={i}
                              onClick={() => {
                                console.log('user', user)
                                if (selectedUser?.name === user.user) {
                                  setSelectedUser(null)
                                  return
                                }
                                setSelectedUser({
                                  name: user.user,
                                  color: softColors[i % softColors.length]
                                })
                              }}
                              style={{
                                background:
                                  selectedUser?.name === user.user &&
                                  'var(--grey-100)'
                              }}
                              className="flex gap-2 items-center cursor-pointer hover-bg-grey-100 p-2 rounded-md px-4"
                            >
                              <div
                                className="p-2 rounded-full"
                                style={{
                                  height: '20px',
                                  width: '20px',
                                  background: softColors[i % softColors.length],
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center'
                                }}
                              >
                                {i + 1}
                              </div>
                              <div>
                                <p className="m-0 font-medium text-grey-700">
                                  {user.user}
                                </p>
                                <p className="m-0 text-xs text-grey-500">
                                  {user.count}
                                </p>
                              </div>
                            </div>
                          )
                        })}
                      </>
                    )}
                  </div>
                </div>

                <div className="">
                  <p className="m-0 px-3 mt-3 text-xs text-grey-500">
                    {selectedUser ? selectedUser.name + ' ' : 'All users '}
                    activity by week
                  </p>
                  <div className="grid gap-4 px-3 p-2 mt-4">
                    <Bar
                      key={selectedUser?.name}
                      options={{
                        responsive: true,
                        plugins: {
                          legend: {
                            display: false
                          }
                        },
                        scales: {
                          x: {
                            stacked: true
                          },
                          y: {
                            stacked: true
                          }
                        },
                        datasets: {
                          bar: {
                            maxBarThickness: 110
                          }
                        }
                      }}
                      data={{
                        labels: Object.keys(weekWiseUserData),
                        datasets: selectedUser
                          ? [
                              {
                                label: selectedUser.name,
                                data: datesData.map(
                                  (date) =>
                                    weekWiseUserData[date][selectedUser.name] ||
                                    0
                                ),
                                backgroundColor: selectedUser.color
                                // one of the soft colors from the array
                              }
                            ]
                          : topUsers.map(({ user }, i) => ({
                              label: user,
                              data: datesData?.map(
                                (date) => weekWiseUserData?.[date]?.[user] || 0
                              ),
                              backgroundColor: softColors[i % softColors.length]
                              // one of the soft colors from the array
                            }))
                      }}
                      plugins={[topLine]}
                    />
                  </div>
                </div>
              </div>
              <div className="grid grid-col-2 mt-3 gap-3">
                <div
                  className="bg-white p-3 rounded-xl"
                  style={{
                    border: '1px solid var(--grey-200)'
                  }}
                >
                  <p className="m-0 px-3 mt-3 text-xs text-grey-500">
                    Total Time Saved
                  </p>

                  <div className="grid grid-col-2 gap-2 ">
                    <div className="grid grid-col-2 gap-2 justify-around px-4 mt-4">
                      {timeSavings.map((info, i) => {
                        return (
                          <div
                            key={i}
                            className=""
                            style={{
                              borderLeft: '2px solid',
                              borderColor: info.color,
                              padding: '4px 10px',
                              height: '50px'
                              // borderRadius: '10px'
                            }}
                          >
                            <p className="text-lg m-0">
                              {/* {info.time} */}
                              {moment
                                .duration(info.time, 'minutes')
                                .asHours()
                                .toFixed(0) < 1
                                ? info.time + ' mins'
                                : moment
                                    .duration(info.time, 'minutes')
                                    .asHours()
                                    .toFixed(0) + ' hrs'}
                            </p>
                            <div className="flex items-center gap-1">
                              <p className="m-0 font-medium text-grey-500 text-xs ">
                                {info.name}
                              </p>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                    <div
                      className="grid place-content-center p-4 "
                      style={{
                        maxHeight: '230px',
                        width: '300px'
                      }}
                    >
                      <Doughnut
                        options={{
                          cutout: 70,
                          plugins: {
                            legend: {
                              display: false
                            }
                          }
                        }}
                        plugins={[textCenter]}
                        data={{
                          labels: [...timeSavings.map((time) => time.name)],
                          datasets: [
                            {
                              data: [...timeSavings.map((time) => time.time)],
                              backgroundColor: [
                                ...timeSavings.map((time) => time.color)
                              ],
                              borderColor: 'transparent'
                            }
                          ]
                        }}
                        style={{
                          width: '60px',
                          height: '60px'
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default UserInsight

const allUsers = (weekWiseUserData) => {
  const users = Object.keys(weekWiseUserData).reduce((acc, date) => {
    const users = weekWiseUserData[date]
    Object.keys(users).forEach((user) => {
      if (acc[user]) {
        acc[user] += users[user]
      } else {
        acc[user] = users[user]
      }
    })
    return acc
  }, {})

  console.log('users', users)

  const topUsers = Object.keys(users).sort((a, b) => users[b] - users[a])

  const finalResults = topUsers.map((user) => {
    return {
      user,
      count: users[user]
    }
  })

  return finalResults
}

const softColors = [
  '#cdb4db',
  '#ffafcc',
  '#bee1e6',
  '#bde0fe',
  '#ffd7ba',
  '#ffb5a7',
  '#a2d2ff',
  '#ffc8dd',
  '#fec89a',
  '#e2ece9',
  '#fcf6bd'
]
