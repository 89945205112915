import {
  ADD_DOCUMENT_TO_CHAT,
  ADD_CONTENT_TO_CHAT,
  SET_CHAT_STATE,
  CLEAR_DOCUMENT_TO_CHAT,
  ATTACH_ALERT_TO_CHAT
} from '../../../actionTypes'

const addDocumentToChat = (data) => async (dispatch) => {
  dispatch({
    type: ADD_DOCUMENT_TO_CHAT,
    payload: data
  })
  dispatch({
    type: SET_CHAT_STATE,
    payload: 'open'
  })
}

const attachAlertToChat = (data) => async (dispatch) => {
  dispatch({
    type: ATTACH_ALERT_TO_CHAT,
    payload: data
  })
}

const clearDocumentFromChat = (data) => async (dispatch) => {
  dispatch({
    type: CLEAR_DOCUMENT_TO_CHAT,
    payload: {}
  })
}

const addContentToChat = (data) => async (dispatch) => {
  dispatch({
    type: ADD_CONTENT_TO_CHAT,
    payload: data
  })
  dispatch({
    type: SET_CHAT_STATE,
    payload: 'open'
  })
}

const setChatState = (data) => async (dispatch) => {
  dispatch({
    type: SET_CHAT_STATE,
    payload: data
  })
}

export {
  addDocumentToChat,
  addContentToChat,
  clearDocumentFromChat,
  setChatState,
  attachAlertToChat
}
