import {
  Avatar,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import TextInput from '../../components/Lexical/ui/TextInput'
import Button2 from '../../components/Button/Button2'
import styled from '@emotion/styled'
import { LockIcon, SemiCircleIcon } from '../../components/Icons/Icons'
import { useSelector } from 'react-redux'
import SearchNew from '../../sections/ListFilter/SearchNew'
import {
  getColorsForInitials,
  getInitialsFromUserName
} from '../../utils/String'

const CollectionModal = ({
  isOpen,
  handleCreateClose,
  update,
  create,
  onClose,
  type = 'create' || 'edit',
  collectionInfo
}) => {
  const [sharedUsers, setSharedUsers] = useState([])
  const [isPrivate, setIsPrivate] = useState(false)
  const [collectionName, setCollectionName] = useState('')
  const [loading, setLoading] = useState(false)
  const domainUsers = useSelector(
    (state) => state.authenticate.user?.domain?.domain_users || []
  )

  const [userOptions, setUserOptions] = useState([])
  const [searchUser, setSearchUser] = useState('')

  useEffect(() => {
    if (type === 'edit') {
      setCollectionName(collectionInfo?.name)
      setIsPrivate(collectionInfo?.visibility === 'private')
    }

    const userNames = domainUsers.map((user) => {
      return {
        value: user.id,
        label: user.user_name,
        email: user.email
      }
    })

    const shared_user_ids =
      collectionInfo?.access_config.map((user) => user.value) || []

    const usersTemp = userNames.filter(
      (user) => !shared_user_ids.includes(user.value)
    )

    setSharedUsers([
      ...userNames.filter((user) => shared_user_ids.includes(user.value))
    ])

    setUserOptions(usersTemp)

    console.log(
      'setSharedUsers',
      collectionInfo,
      userNames,
      shared_user_ids,
      userNames.filter((user) => shared_user_ids.includes(user.value))
    )
  }, [type])

  useEffect(() => {
    if (!isOpen) {
      setCollectionName('')
      setIsPrivate(false)
      setLoading(false)
      setSharedUsers([])
    }
  }, [isOpen])

  return (
    <Dialog
      maxWidth="sm"
      open={isOpen}
      onClose={handleCreateClose}
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '10px',
          padding: '14px'
          // height: '110px'
        }
      }}
    >
      <div className="w-96 flex flex-col justify-between h-full">
        <div className="flex flex-col gap-1">
          <label
            className="text-xxs text-grey-600"
            style={{
              marginLeft: '4px'
            }}
          >
            Collection Name
          </label>
          <input
            type="text"
            className="input-base rounded-lg p-2 bg-grey-50 border-grey-200 border-1 font-poppins"
            placeholder="Knowledge Base..."
            autoFocus
            value={collectionName}
            onKeyUp={(e) => {
              if (e.target.value.length === 0) {
                return
              }

              if (e.key === 'Enter') {
                if (type === 'create') {
                  create(
                    collectionName,
                    isPrivate ? 'private' : 'public',
                    sharedUsers
                  )
                  setLoading(true)
                } else {
                  setLoading(true)
                  update({
                    name: collectionName,
                    visibility: isPrivate ? 'private' : 'public',
                    id: collectionInfo?.id,
                    sharedUsers
                  })
                }
              }
            }}
            onChange={(e) => setCollectionName(e.target.value)}
          />
        </div>
        {isPrivate && (
          <div
            className=""
            style={{
              minHeight: 200
            }}
          >
            <p
              className="m-0 text-sm font-medium ml-1 mb-1 mt-2"
              style={{
                paddingTop: '10px'
              }}
            >
              Share access
            </p>
            <SearchNew
              autoComplete
              options={userOptions}
              value={searchUser}
              onChange={(value) => {
                setSearchUser(value)
              }}
              onSelect={(value) => {
                setUserOptions((prev) => {
                  return prev.filter((user) => user.value !== value.value)
                })

                setSharedUsers([
                  ...sharedUsers,
                  {
                    ...value
                  }
                ])
              }}
            />

            <div className="mt-3">
              {sharedUsers.map((data, index) => {
                const { label, email, value } = data

                return (
                  <div
                    key={index}
                    className="flex items-center justify-between px-2"
                  >
                    <div key={label} className="flex items-center gap-2 mb-2">
                      <Avatar
                        sx={{
                          backgroundColor: getColorsForInitials(label),
                          height: '32px',
                          width: '32px',
                          fontSize: '14px'
                        }}
                      >
                        {getInitialsFromUserName(label?.toUpperCase())}
                      </Avatar>

                      <div className="">
                        <p className="m-0 text-sm font-medium">{label}</p>
                        <p className="m-0 text-xs text-grey-600">{email}</p>
                      </div>
                    </div>
                    <Button2
                      style={{
                        padding: '4px 8px',
                        fontSize: '10px'
                      }}
                      onClick={() => {
                        setUserOptions([
                          ...userOptions,
                          {
                            value,
                            label,
                            email
                          }
                        ])

                        setSharedUsers((prev) => {
                          return prev.filter((user) => user.value !== value)
                        })
                      }}
                      secondary
                    >
                      remove
                    </Button2>
                  </div>
                )
              })}
            </div>
          </div>
        )}
        <div className="flex items-center gap-2 mt-3 ml-1">
          <p
            className={`text-xxs font-medium m-0 ${
              isPrivate ? 'text-grey-800' : 'text-grey-500'
            }`}
          >
            Private collection
          </p>
          <AntSwitch
            checked={isPrivate}
            onChange={(e) => setIsPrivate(e.target.checked)}
            inputProps={{ 'aria-label': 'ant design' }}
          />

          <div className="flex gap-1 ml-auto">
            <Button2 variant="outlined" onClick={onClose} secondary noOutline>
              Cancel
            </Button2>
            <Button2
              variant="outlined"
              onClick={() => {
                if (type === 'create') {
                  create(
                    collectionName,
                    isPrivate ? 'private' : 'public',
                    sharedUsers
                  )
                  setLoading(true)
                } else {
                  setLoading(true)
                  update({
                    name: collectionName,
                    visibility: isPrivate ? 'private' : 'public',
                    id: collectionInfo?.id,
                    access_config: sharedUsers
                  })
                }
              }}
              primary
              disabled={collectionName === ''}
            >
              {loading ? (
                <SemiCircleIcon className="text-grey-200 animate-spin size-4" />
              ) : type === 'create' ? (
                'Create'
              ) : (
                'Update'
              )}
            </Button2>
          </div>
        </div>{' '}
      </div>
    </Dialog>
  )
}

export default CollectionModal

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)'
    }
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'var(--grey-800)',
        ...theme.applyStyles('dark', {
          backgroundColor: '#177ddc'
        })
      }
    }
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200
    })
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgba(255,255,255,.35)'
    })
  }
}))
