export const USER_SIGN_IN = 'USER_SIGN_IN'
export const USER_SIGN_OUT = 'USER_SIGN_OUT'
export const USER_CONFIG_UPDATE = 'USER_CONFIG_UPDATE'
export const DOMAIN_CONFIG_UPDATE = 'DOMAIN_CONFIG_UPDATE'
export const REVERT_FAVPROMPT_DOMAIN_CONFIG = 'REVERT_FAVPROMPT_DOMAIN_CONFIG'

export const CREATE_DOMAIN = 'CREATE_DOMAIN'
export const UPDATE_DOMAIN = 'UPDATE_DOMAIN'

export const FETCH_PROPOSAL = 'FETCH_PROPOSAL'
export const FETCH_PROPOSALS = 'FETCH_PROPOSALS'
export const UPDATE_PROPOSALS = 'UPDATE_PROPOSALS'
export const DELETE_PROPOSALS = 'DELETE_PROPOSALS'
export const GROUP_DELETE_PROPOSALS = 'GROUP_DELETE_PROPOSALS'
export const GROUP_UPDATE_PROPOSALS = 'GROUP_UPDATE_PROPOSALS'
export const SET_PROPOSAL_DROPDOWN = 'SET_PROPOSAL_DROPDOWN'
export const SET_PROPOSAL_DRAWER_DATA = 'SET_PROPOSAL_DRAWER_DATA'
export const SET_PROPOSAL_FILTER_DATA = 'SET_PROPOSAL_FILTER_DATA'
export const SET_PROPOSAL_DATA = 'SET_PROPOSAL_DATA'

export const SEARCH_CONTENT = 'SEARCH_CONTENT'
export const SET_SIDEBAR_ACTIVE = 'SET_SIDEBAR_ACTIVE'
export const SET_LOADING = 'SET_LOADING'
export const TOASTER_ALERT = 'TOASTER_ALERT'
export const FETCH_PROMPTS = 'FETCH_PROMPTS'

export const GET_WORKSPACES = 'GET_WORKSPACES'
export const ADD_WORKSPACES = 'ADD_WORKSPACES'
export const CREATE_WORKSPACE = 'CREATE_WORKSPACE'
export const UPDATE_WORKSPACE = 'UPDATE_DOCUMENT'
export const DELETE_WORKSPACE = 'DELETE_DOCUMENT'

export const CREATE_SAVE_CONTENT = 'CREATE_SAVE_CONTENT'
export const GET_SAVED_CONTENT = 'GET_SAVED_CONTENT'

export const FETCH_ALL_DOMAIN_LIST = 'FETCH_ALL_DOMAIN_LIST'
export const FETCH_DOMAIN_SETTINGS = 'FETCH_DOMAIN_SETTINGS'
export const UPDATE_DOMAIN_SETTINGS = 'UPDATE_DOMAIN_SETTINGS'
export const FETCH_DOMAIN_INVITES = 'FETCH_DOMAIN_INVITES'
export const ADD_DOMAIN_INVITES = 'ADD_DOMAIN_INVITES'
export const UPDATE_DOMAIN_INVITES = 'UPDATE_DOMAIN_INVITES'
export const DELETE_DOMAIN_INVITES = 'DELETE_DOMAIN_INVITES'

export const FETCH_LIBRARY = 'FETCH_LIBRARY'
export const ADD_LIBRARY = 'ADD_LIBRARY'
export const CLEAR_LIBRARY = 'CLEAR_LIBRARY'
export const CREATE_LIBRARY_DOCUMENT = 'CREATE_LIBRARY_DOCUMENT'
export const CLEAR_LIBRARY_DOCUMENT = 'CLEAR_LIBRARY_DOCUMENT'
export const UPDATE_LIBRARY_DOCUMENT = 'UPDATE_LIBRARY_DOCUMENT'
export const DELETE_LIBRARY_DOCUMENT = 'DELETE_LIBRARY_DOCUMENT'
export const CREATE_LIBRARY_FROM_EDITOR = 'CREATE_LIBRARY_FROM_EDITOR'
export const FETCH_LIBRARY_COLLECTIONS = 'FETCH_LIBRARY_COLLECTIONS'
export const UPDATE_LIBRARY_COLLECTIONS = 'UPDATE_LIBRARY_COLLECTIONS'
export const SET_ACTIVE_LIBRARY_TAG = 'SET_ACTIVE_LIBRARY_TAG'
export const DELETE_LIBRARY_COLLECTIONS = 'DELETE_LIBRARY_COLLECTIONS'
export const UPDATE_LIBRARY_SUGGESTION = 'UPDATE_LIBRARY_SUGGESTION'
export const UPDATE_LIBRARY_COLLECTION_NAME = 'UPDATE_LIBRARY_COLLECTION_NAME'
export const UPDATE_LIBRARY_VARIAITONS = 'UPDATE_VARIAITONS'
export const UPDATE_LIBRARY_STATUS = 'UPDATE_LIBRARY_STATUS'
export const UPDATE_LIBRARY_COLLECTIONS_STATUS =
  'UPDATE_LIBRARY_COLLECTIONS_STATUS'
export const UPDATE_LIBRARY_COLLECTIONS_VIEWS =
  'UPDATE_LIBRARY_COLLECTIONS_VIEWS'

export const FETCH_ANALYTIC = 'FETCH_ANALYTIC'
export const FETCH_ANALYTICS = 'FETCH_ANALYTICS'
export const UPDATE_ANALYTICS = 'UPDATE_ANALYTICS'
export const DELETE_ANALYTIC = 'DELETE_ANALYTIC'
export const FETCH_STYLE_SETTINGS = 'FETCH_STYLE_SETTINGS'
export const UPDATE_STYLE_SETTINGS = 'UPDATE_STYLE_SETTINGS'
export const FETCH_CHART_DATA = 'FETCH_CHART_DATA'

export const SET_UPLOADER = 'SET_UPLOADER'
export const RESIZE_UPLOADER = 'RESIZE_UPLOADER'

export const ADD_RECENT_SEARCH = 'ADD_RECENT_SEARCH'
export const FETCH_SEARCH_HISTORY = 'FETCH_SEARCH_HISTORY'
export const CLEAR_SEARCH_HISTORY = 'CLEAR_SEARCH_HISTORY'
export const SESSION_CHAT_HISTORY = 'SESSION_CHAT_HISTORY'
export const UPDATE_SESSION_CHAT_HISTORY = 'UPDATE_SESSION_CHAT_HISTORY'
export const DELETE_SESSION_CHAT_HISTORY = 'DELETE_SESSION_CHAT_HISTORY'
export const CLEAR_SESSION_CHAT_HISTORY = 'CLEAR_SESSION_CHAT_HISTORY'
export const ADD_CHAT_FILTER = 'ADD_CHAT_FILTER'
export const SESSION_CHAT_HISTORY_ADD = 'SESSION_CHAT_HISTORY_ADD'
export const SESSION_CHAT_HISTORY_ADD_SINGLE = 'SESSION_CHAT_HISTORY_ADD_SINGLE'
export const DELETE_SESSION_CHAT_HISTORY_REVERT =
  'DELETE_SESSION_CHAT_HISTORY_REVERT'
export const ADD_SESSION_CHAT = 'ADD_SESSION_CHAT'
export const UPDATE_SESSION_CHAT = 'UPDATE_SESSION_CHAT'
export const ADD_NEW_SESSION = 'ADD_NEW_SESSION'
export const UPDATE_SESSION_CHAT_FEEDBACK = 'UPDATE_SESSION_CHAT_FEEDBACK'
export const UPDATE_SESSION_CHAT_TITLE = 'UPDATE_SESSION_CHAT_TITLE'
export const UPDATE_SESSION_CHAT_PARTIAL = 'UPDATE_SESSION_CHAT_PARTIAL'
export const ADD_DOCUMENT_TO_CHAT = 'ADD_DOCUMENT_TO_CHAT'
export const ATTACH_ALERT_TO_CHAT = 'ATTACH_ALERT_TO_CHAT'
export const ADD_CONTENT_TO_CHAT = 'ADD_CONTENT_TO_CHAT'
export const CLEAR_DOCUMENT_TO_CHAT = 'CLEAR_DOCUMENT_TO_CHAT_FILTER'
export const CLEAR_CONTENT_TO_CHAT = 'CLEAR_CONTENT_TO_CHAT'
export const SET_CHAT_STATE = 'SET_CHAT_STATE'

export const FETCH_ASSET = 'FETCH_ASSET'
export const FETCH_ASSETS = 'FETCH_ASSETS'
export const DELETE_ASSET = 'DELETE_ASSET'
export const APPEND_ASSETS = 'APPEND_ASSETS'
export const SET_INITIAL_ASSET = 'SET_INITIAL_ASSET'
export const RESET_ASSET = 'RESET_ASSET'
export const HIDE_ASSET = 'HIDE_ASSET'
export const UPDATE_ASSET = 'UPDATE_ASSET'
export const UPDATE_ASSET_STATE = 'UPDATE_ASSET_STATE'

export const FETCH_RFX_DOCUMENTS = 'FETCH_RFX_DOCUMENTS'
export const FETCH_RFX = 'FETCH_RFX'
export const UPDATE_RFX = 'UPDATE_RFX'
export const DELETE_RFX = 'DELETE_RFX'

export const FETCH_PEOPLE = 'FETCH_PEOPLE'
export const CLEAR_PEOPLE = 'CLEAR_PEOPLE'
export const UPDATE_PEOPLE = 'UPDATE_PEOPLE'
export const ADD_PEOPLE = 'ADD_PEOPLE'
export const DELETE_PEOPLE = 'DELETE_PEOPLE'
export const MERGE_PEOPLE = 'MERGE_PEOPLE'
export const UPDATE_PEOPLE_TAG = 'UPDATE_PEOPLE_TAG'
export const UPDATE_PEOPLE_NAME = 'UPDATE_PEOPLE_NAME'
export const UPDATE_PEOPLE_NOTES = 'UPDATE_PEOPLE_NOTES'

export const FETCH_PROJECTS = 'FETCH_PROJECTS'
export const UPDATE_PROJECT_TAG = 'UPDATE_PROJECT_TAG'
export const UPDATE_PROJECT_NAME = 'UPDATE_PROJECT_NAME'

export const FETCH_TAGS = 'FETCH_TAGS'
export const UPDATE_KEY_TAG = 'UPDATE_KEY_TAG'
export const UPDATE_VALUE_TAG = 'UPDATE_VALUE_TAG'
export const UPDATE_VALUE_MULTI_TAG = 'UPDATE_VALUE_MULTI_TAG'
export const UPDATE_MULTI_VALUE_TAG = 'UPDATE_MULTI_VALUE_TAG'
export const UPDATE_NEW_VALUE_TAG = 'UPDATE_NEW_VALUE_TAG'

export const FETCH_COLLECTIONS = 'FETCH_COLLECTIONS'
export const REFRESH_COLLECTIONS = 'REFRESH_COLLECTIONS'
export const ADD_TO_COLLECTIONS = 'ADD_TO_COLLECTIONS'
export const UPDATE_ADDED_COLLECTIONS = 'UPDATE_ADDED_COLLECTIONS'
export const UPDATE_ACTIVE_COLLECTION = 'UPDATE_ACTIVE_COLLECTION'
export const UPDATE_CHILDREN_COLLECTIONS = 'UPDATE_CHILDREN_COLLECTIONS'
export const UPDATE_COLLECTIONS = 'UPDATE_COLLECTIONS'
export const SET_COLLECTIONS_EXPANDED = 'SET_COLLECTIONS_EXPANDED'
export const DELETE_COLLECTION = 'DELETE_COLLECTION'

export const ANALYTICS_COMPLIANCE_DATA = 'ANALYTICS_COMPLIANCE_DATA'

export const FETCH_CLIENTS_LIST = 'FETCH_CLIENTS_LIST'
export const UPDATE_CLIENTS_LIST = 'UPDATE_CLIENTS_LIST'
